




































import Vue from 'vue';
import { AbilityScoreScores } from '@/types';
import AbilityScore from './AbilityScore.vue';

export default Vue.extend({
  name: 'AbilityScores',
  components: {
    AbilityScore,
  },
  props: {
    abilities: {
      type: Object as () => AbilityScoreScores,
      required: true,
    },
  },
});
