




































import Vue from 'vue';
import { Character } from '@/types';
import Button from './Button.vue';
import SvgIcon from './SvgIcon.vue';
import {
  alignment as alignmentDetails,
  race as raceDetails,
  class as classDetails,
} from '../details.json';

export default Vue.extend({
  name: 'Share',
  components: {
    Button,
    SvgIcon,
  },
  props: {
    character: {
      type: Object as () => Character,
      required: true,
    },
  },
  computed: {
    canUseShareApi(): boolean {
      return typeof navigator.share !== 'undefined';
    },
    description(): string {
      const classes = this.character.class;
      const classNames = classes.map((className) => classDetails[className].name);
      const { level, alignment, race } = this.character;
      let description = `I am a level ${level} ${alignmentDetails[alignment].name}`;
      description += ` ${raceDetails[race].name} ${classNames.join('/')}!`;
      description += ' Find out what kind of D&D character you would be!';
      return description;
    },
    shareUrl(): string {
      return encodeURI(process.env.VUE_APP_BASE_URL);
    },
    facebookUrl(): string {
      return `https://www.facebook.com/sharer.php?u=${this.shareUrl}`;
    },
    twitterUrl(): string {
      let url = `https://twitter.com/intent/tweet?url=${this.shareUrl}`;
      url += `&text=${encodeURIComponent(this.description)}`;
      url += '&hashtags=dnd,dungeonsanddragons,dndcharacter,dndnme';
      return url;
    },
  },
  methods: {
    share() {
      try {
        navigator.share({
          title: 'What D&D character are you? | D&D & Me',
          text: this.description,
          url: process.env.VUE_APP_BASE_URL,
        }).then(() => {
          // should we do something here?
        }).catch(() => {
          // do nothing...
        });
      } catch (_error) {
        // do nothing...
      }
    },
  },
});
