





























import Vue from 'vue';
import { Question } from '../types';

export default Vue.extend({
  name: 'Question',
  props: {
    question: {
      type: Object as () => Question,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    answer: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      currentValue: this.answer,
      activeClass:
        'border-red-800 text-white bg-red-600',
      inactiveClass: 'border-red-300 bg-white md:bg-red-50',
    };
  },
  watch: {
    answer(val) {
      this.currentValue = val;
    },
  },
  methods: {
    modify(event: Event) {
      const target = event.target as HTMLInputElement;
      const { options } = this.question;
      const option = options.find((opt) => opt.value === target.value);
      if (option) {
        const { modifiers } = option;
        this.$emit('modify', modifiers);
      }
    },
  },
});
