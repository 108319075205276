






















import { ScoreDetails } from '@/types';
import Vue from 'vue';

export default Vue.extend({
  name: 'DetailedResult',
  props: {
    title: {
      type: String,
      required: true,
    },
    results: {
      type: Array as () => ScoreDetails[],
      required: true,
    },
  },
});
