













import Vue from 'vue';

export default Vue.extend({
  name: 'ProgressBar',
  props: {
    percentage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    barStyle() {
      const color = `var(--color-primary) ${this.percentage}%`;
      return `background: linear-gradient(to right, ${color}, var(--color-secondary) 0);`;
    },
  },
});
