


















































import Vue from 'vue';
import {
  Alignment,
  Character,
  ClassName,
  Details,
  Race,
} from '@/types';
import {
  alignment as alignmentDetails,
  race as raceDetails,
  class as classDetails,
} from '../details.json';
import AbilityScores from './AbilityScores.vue';
import SvgIcon from './SvgIcon.vue';
import Separator from './Separator.vue';
import StatDetails from './StatDetails.vue';

export default Vue.extend({
  name: 'CharacterSheet',
  components: {
    AbilityScores,
    SvgIcon,
    Separator,
    StatDetails,
  },
  props: {
    character: {
      type: Object as () => Character,
      required: true,
    },
  },
  data() {
    return {
      alignmentDetails: alignmentDetails as {
        [key in Alignment]: Details
      },
      raceDetails: raceDetails as {
        [key in Race]: Details
      },
      classDetails: classDetails as {
        [key in ClassName]: Details
      },
    };
  },
  computed: {
    primaryClass(): ClassName {
      return this.character.class[0];
    },
    secondaryClass(): ClassName|null {
      const characterClass = this.character.class;
      return characterClass.length > 1 ? characterClass[1] : null;
    },
  },
});
