







import { Details } from '@/types';
import Vue from 'vue';

export default Vue.extend({
  name: 'StatDetails',
  props: {
    name: {
      type: String,
      required: true,
    },
    stat: {
      type: Object as () => Details,
      required: true,
    },
  },
});
