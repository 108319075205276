












import Vue from 'vue';
import Button from './Button.vue';

export default Vue.extend({
  name: 'NoResults',
  components: {
    Button,
  },
});
