



















import Vue from 'vue';

export default Vue.extend({
  name: 'AbilityScore',
  props: {
    name: {
      type: String,
      required: true,
    },
    score: {
      type: Number,
      required: true,
    },
  },
  computed: {
    modifier(): number {
      return Math.floor((this.score - 10) / 2);
    },
  },
});
