































import Vue from 'vue';
import { extractScores } from '@/helpers/scores';
import { buildCharacter } from '@/helpers/character';
import { Character, Modifier, Scores } from '@/types';
import splitbee from '@splitbee/web';
import CharacterSheet from '../components/CharacterSheet.vue';
import DetailedResults from '../components/DetailedResults.vue';
import NoResults from '../components/NoResults.vue';
import ResetModal from '../components/ResetModal.vue';
import Button from '../components/Button.vue';
import Separator from '../components/Separator.vue';
import Share from '../components/Share.vue';

export default Vue.extend({
  name: 'Character',
  components: {
    CharacterSheet,
    DetailedResults,
    Button,
    NoResults,
    ResetModal,
    Separator,
    Share,
  },
  data() {
    return {
      scores: [] as Array<Modifier>[],
      showModal: false,
    };
  },
  computed: {
    finalScores(): Scores {
      const modifiers = this.scores.flat().filter(Boolean);
      return extractScores(modifiers);
    },
    character(): Character {
      return buildCharacter(this.finalScores);
    },
  },
  methods: {
    /**
     * Reset all saved answers
     */
    resetAnswers() {
      localStorage.removeItem('scores');
      this.showModal = false;
      this.scores = [] as Array<Modifier>[];
    },
  },
  beforeMount() {
    // load saved scores if there are any
    const savedScores = localStorage.getItem('scores');
    if (savedScores) {
      try {
        this.scores = JSON.parse(savedScores) as Array<Modifier>[];
      } catch (e) {
        // invalid data, remove it
        localStorage.removeItem('scores');
      }
    }
  },
  mounted() {
    // check if we have already sent this result
    if (this.scores) {
      const dispatched = localStorage.getItem('is-dispatched');
      if (dispatched === null) {
        // track all in a single event
        splitbee.track('Character', {
          race: this.character.race,
          class: this.character.class.join('/'),
          alignment: this.character.alignment,
        });

        // track each class separately too
        this.character.class.forEach((className) => {
          splitbee.track('Class', {
            value: className,
          });
        });
        localStorage.setItem('is-dispatched', 'true');
      }
    }
  },
  metaInfo: {
    title: 'My Character | D&D & Me',
    meta: [
      { property: 'og:title', content: 'My Character | D&D & Me' },
      { property: 'twitter:title', content: 'My Character | D&D & Me' },
    ],
  },
});
